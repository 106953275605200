import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  DollarSign, 
  Users, 
  Star, 
  ShoppingBag, 
  TrendingUp,
  Gift,
  Building,
  Crown
} from 'lucide-react';
import { useTheme } from '../styles/theme';
import { useView } from './ViewToggle';

const DevelopmentProgress = () => {
  const [activeTab, setActiveTab] = useState('subscription');
  const theme = useTheme();
  const { isInvestorView } = useView();

  // Public version of subscription plans
  const publicSubscriptionPlans = {
    subscription: {
      title: "Multi-Tiered Subscription",
      description: "Access premium features with our subscription plans",
      icon: Crown,
      details: [
        {
          tier: "Free",
          price: "$0/month",
          features: [
            "Core social features",
            "Basic anime profile",
            "Standard chat",
            "Community access"
          ]
        },
        {
          tier: "Plus",
          price: "$4.99/month",
          features: [
            "Ad-free experience",
            "Enhanced anime features",
            "Priority support",
            "Custom themes"
          ]
        }
      ]
    },
    creator: {
      title: "Creator Features",
      description: "Tools for content creators",
      icon: Star,
      features: [
        {
          title: "Content Creation",
          description: "Create and share unique content"
        },
        {
          title: "Community Building",
          description: "Build your anime community"
        }
      ]
    }
  };

  // Investor-only subscription details
  const investorSubscriptionPlans = {
    subscription: {
      title: "Multi-Tiered Subscription",
      description: "Flexible subscription plans for enhanced features and experiences",
      icon: Crown,
      details: [
        {
          tier: "Free",
          price: "$0/month",
          features: [
            "Core social features",
            "Basic anime profile",
            "Standard chat",
            "Ad-supported experience"
          ]
        },
        {
          tier: "Plus",
          price: "$4.99/month",
          features: [
            "Ad-free experience",
            "Enhanced anime features",
            "Priority chat support",
            "Custom themes"
          ]
        },
        {
          tier: "Premium",
          price: "$9.99/month",
          features: [
            "All Plus features",
            "Exclusive content",
            "Priority in connections",
            "Advanced social tools",
            "Early access to features"
          ]
        }
      ]
    },
    creator: {
      title: "Creator Economy",
      description: "Monetization tools for content creators and influencers",
      icon: Star,
      features: [
        {
          title: "Creator Support",
          value: "20%",
          description: "Platform commission on earnings"
        },
        {
          title: "Tipping System",
          description: "Direct support from followers"
        },
        {
          title: "Exclusive Content",
          description: "Subscription-based content sharing"
        },
        {
          title: "Brand Collaborations",
          description: "Facilitated partnership opportunities"
        }
      ]
    },
    partnerships: {
      title: "Strategic Partnerships",
      description: "Collaboration with brands and anime industry partners",
      icon: Building,
      features: [
        {
          title: "Sponsored Content",
          description: "Integrated brand promotions"
        },
        {
          title: "Event Hosting",
          description: "Virtual anime premieres and fan meetups"
        },
        {
          title: "Content Licensing",
          description: "Partnerships with anime studios"
        },
        {
          title: "Data Insights",
          description: "Anonymized trend analysis for partners"
        }
      ]
    }
  };

  // Use different plans based on view type
  const plans = isInvestorView ? investorSubscriptionPlans : publicSubscriptionPlans;

  // Metrics shown to everyone
  const publicMetrics = [
    {
      label: "Development Progress",
      value: "85%",
      description: "Platform completion"
    },
    {
      label: "Active Features",
      value: "15+",
      description: "Core features launched"
    }
  ];

  // Additional metrics for investors only
  const investorMetrics = [
    {
      label: "Monthly Revenue",
      value: "$250K+",
      description: "Current monthly revenue"
    },
    {
      label: "User Growth",
      value: "127%",
      description: "Month over month growth"
    }
  ];

  const metrics = isInvestorView ? [...publicMetrics, ...investorMetrics] : publicMetrics;

  return (
    <section className="section-cyber">
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Development Status</span>
          </h2>
          <p className="text-xl text-text-secondary max-w-2xl mx-auto">
            {isInvestorView 
              ? "Current development progress and revenue metrics"
              : "Platform development status and features"
            }
          </p>
        </motion.div>

        {/* Development Metrics */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          {metrics.map((metric, index) => (
            <motion.div
              key={metric.label}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="card-cyber p-6 hover-glow"
            >
              <div className="text-2xl font-bold text-glow mb-2">{metric.value}</div>
              <div className="text-primary font-semibold mb-1">{metric.label}</div>
              <p className="text-text-secondary text-sm">{metric.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Subscription Plans */}
        <div className="glass p-8 rounded-xl mb-12">
          <h3 className="text-2xl font-bold mb-6">Subscription Plans</h3>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            {plans.subscription.details.map((tier) => (
              <motion.div
                key={tier.tier}
                className="card-cyber p-6 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <h4 className="font-semibold text-glow mb-2">{tier.tier}</h4>
                <div className="text-xl font-bold text-primary mb-4">{tier.price}</div>
                <ul className="space-y-2">
                  {tier.features.map((feature, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <Star size={16} className="text-primary" />
                      <span className="text-text-secondary text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Additional Features Section - Only for Investors */}
        {isInvestorView && (
          <div className="grid lg:grid-cols-2 gap-8">
            {/* Creator Economy */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="glass p-6 rounded-xl"
            >
              <h3 className="text-xl font-bold mb-4">Creator Economy</h3>
              <div className="grid grid-cols-2 gap-4">
                {plans.creator.features.map((feature) => (
                  <div key={feature.title} className="card-cyber p-4">
                    <h4 className="font-semibold text-glow mb-2">{feature.title}</h4>
                    {feature.value && (
                      <div className="text-xl font-bold text-primary mb-2">{feature.value}</div>
                    )}
                    <p className="text-text-secondary text-sm">{feature.description}</p>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Partnerships */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="glass p-6 rounded-xl"
            >
              <h3 className="text-xl font-bold mb-4">Strategic Partnerships</h3>
              <div className="grid grid-cols-2 gap-4">
                {plans.partnerships.features.map((feature) => (
                  <div key={feature.title} className="card-cyber p-4">
                    <h4 className="font-semibold text-glow mb-2">{feature.title}</h4>
                    <p className="text-text-secondary text-sm">{feature.description}</p>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        )}

        {/* Public Features Section - Only for Public View */}
        {!isInvestorView && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="grid md:grid-cols-3 gap-6"
          >
            {plans.creator.features.map((feature) => (
              <div key={feature.title} className="card-cyber p-6">
                <h4 className="font-semibold text-glow mb-2">{feature.title}</h4>
                <p className="text-text-secondary text-sm">{feature.description}</p>
              </div>
            ))}
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default DevelopmentProgress;