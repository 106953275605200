import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  TrendingUp, 
  Users, 
  Globe, 
  Rocket,
  Target,
  ArrowRight,
  Building,
  Flag,
  Award,
  Star
} from 'lucide-react';
import { useTheme } from '../styles/theme';
import { useView } from './ViewToggle';

const GrowthStrategy = () => {
  const [activePhase, setActivePhase] = useState(0);
  const { isInvestorView } = useView();

  // Public growth phases - Less specific metrics
  const publicPhases = [
    {
      title: "Phase 1",
      fullTitle: "Phase 1: Launch",
      mobileTitle: "Launch",
      timeline: "Initial",
      subtitle: "Platform Launch",
      targets: {
        focus: "Community Building",
        reach: "Global",
        features: "Core Platform",
        timeframe: "Launch Phase"
      },
      strategies: [
        {
          title: "Community Focus",
          points: [
            "User engagement",
            "Feature rollout",
            "Community events",
            "Content creation"
          ]
        },
        {
          title: "Platform Growth",
          points: [
            "User feedback",
            "Feature enhancement",
            "Community tools",
            "Support system"
          ]
        }
      ]
    },
    {
      title: "Phase 2",
      fullTitle: "Phase 2: Growth",
      mobileTitle: "Growth",
      timeline: "Expansion",
      subtitle: "Platform Expansion",
      targets: {
        focus: "Feature Enhancement",
        reach: "International",
        features: "Advanced Tools",
        timeframe: "Growth Phase"
      },
      strategies: [
        {
          title: "Enhanced Features",
          points: [
            "New capabilities",
            "User experience",
            "Premium features",
            "Creator tools"
          ]
        },
        {
          title: "Community Growth",
          points: [
            "Global reach",
            "Local events",
            "Partner program",
            "Content expansion"
          ]
        }
      ]
    },
    {
      title: "Phase 3",
      fullTitle: "Phase 3: Scale",
      mobileTitle: "Scale",
      timeline: "Scale",
      subtitle: "Global Scale",
      targets: {
        focus: "Global Leadership",
        reach: "Worldwide",
        features: "Full Platform",
        timeframe: "Scale Phase"
      },
      strategies: [
        {
          title: "Global Impact",
          points: [
            "Market leadership",
            "Industry innovation",
            "Platform ecosystem",
            "Community impact"
          ]
        },
        {
          title: "Platform Excellence",
          points: [
            "Advanced features",
            "Global integration",
            "Creator economy",
            "Industry leadership"
          ]
        }
      ]
    }
  ];

  // Investor growth phases - Detailed metrics and targets
  const investorPhases = [
    {
      title: "Phase 1",
      fullTitle: "Phase 1: Launch",
      mobileTitle: "Launch",
      timeline: "Year 1",
      subtitle: "Initial Growth",
      targets: {
        users: "2M+",
        revenue: "$10M",
        markets: "4",
        timeframe: "Year 1"
      },
      strategies: [
        {
          title: "Market Entry",
          points: [
            "Focus on major markets",
            "Community growth",
            "Key partnerships",
            "Local content"
          ]
        },
        {
          title: "User Acquisition",
          points: [
            "Social campaigns",
            "Anime events",
            "Early adopters",
            "Referral system"
          ]
        }
      ]
    },
    {
      title: "Phase 2",
      fullTitle: "Phase 2: Scale",
      mobileTitle: "Scale",
      timeline: "Year 2",
      subtitle: "Market Expansion",
      targets: {
        users: "10M+",
        revenue: "$75M",
        markets: "10",
        timeframe: "Year 2"
      },
      strategies: [
        {
          title: "Market Growth",
          points: [
            "Europe expansion",
            "LATAM entry",
            "New partnerships",
            "Local teams"
          ]
        },
        {
          title: "Platform Growth",
          points: [
            "AI features",
            "New formats",
            "Pro tools",
            "Premium tier"
          ]
        }
      ]
    },
    {
      title: "Phase 3",
      fullTitle: "Phase 3: Global",
      mobileTitle: "Global",
      timeline: "Year 3",
      subtitle: "Global Dominance",
      targets: {
        users: "30M+",
        revenue: "$300M",
        markets: "100+",
        timeframe: "Year 3"
      },
      strategies: [
        {
          title: "Global Reach",
          points: [
            "Global presence",
            "Cultural features",
            "Major events",
            "World teams"
          ]
        },
        {
          title: "Full Platform",
          points: [
            "Creator tools",
            "Enterprise",
            "Open API",
            "Extensions"
          ]
        }
      ]
    }
  ];

  const growthPhases = isInvestorView ? investorPhases : publicPhases;

  // Different partnership information for public and investors
  const partnerships = {
    public: [
      {
        category: "Community",
        partners: [
          "Content Creators",
          "Anime Communities",
          "Event Organizers",
          "Fan Groups"
        ]
      },
      {
        category: "Platform",
        partners: [
          "Technology Partners",
          "Security Partners",
          "Content Partners",
          "Service Providers"
        ]
      }
    ],
    investor: [
      {
        category: "Content",
        partners: [
          "Anime Studios",
          "Creators",
          "Streaming",
          "Media"
        ]
      },
      {
        category: "Tech",
        partners: [
          "AI Partners",
          "Cloud",
          "Security",
          "Analytics"
        ]
      },
      {
        category: "Growth",
        partners: [
          "Influencers",
          "Events",
          "PR",
          "Brands"
        ]
      }
    ]
  };

  const currentPartnerships = isInvestorView ? partnerships.investor : partnerships.public;

  return (
    <section className="section-cyber min-h-screen py-8 md:py-20">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative px-4 md:px-0">
        {/* Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8"
        >
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold gradient-text mb-3">
            Growth Strategy
          </h2>
          <p className="text-base md:text-lg text-text-secondary max-w-2xl mx-auto">
            {isInvestorView 
              ? "Strategic roadmap to global market leadership"
              : "Building the future of anime social platforms"
            }
          </p>
        </motion.div>

        {/* Phase Selection */}
        <div className="overflow-x-auto -mx-4 md:mx-0 mb-6">
          <div className="flex md:justify-center min-w-min md:min-w-0 gap-2 px-4 md:px-0">
            {growthPhases.map((phase, index) => (
              <button
                key={index}
                onClick={() => setActivePhase(index)}
                className={`flex items-center gap-2 px-4 md:px-6 py-2.5 rounded-lg transition-all whitespace-nowrap text-sm
                  ${activePhase === index ? 'btn-primary' : 'btn-secondary'}`}
              >
                {index === 0 && <Flag size={16} />}
                {index === 1 && <TrendingUp size={16} />}
                {index === 2 && <Award size={16} />}
                <span className="hidden md:inline">{phase.fullTitle}</span>
                <span className="md:hidden">{phase.mobileTitle}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Phase Details */}
        <motion.div
          key={activePhase}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid lg:grid-cols-2 gap-6"
        >
          {/* Left Column */}
          <div>
            <div className="glass p-4 md:p-6 rounded-xl mb-6">
              <h3 className="text-xl md:text-2xl font-bold mb-2">
                {growthPhases[activePhase].fullTitle}
              </h3>
              <p className="text-text-secondary text-sm mb-4">
                {growthPhases[activePhase].subtitle}
              </p>

              {/* Metrics Grid */}
              <div className="grid grid-cols-2 gap-3">
                {Object.entries(growthPhases[activePhase].targets).map(([key, value]) => (
                  <div key={key} className="card-cyber p-3 text-center">
                    <div className="text-lg md:text-xl font-bold text-glow mb-1">
                      {value}
                    </div>
                    <div className="text-text-secondary text-xs capitalize">{key}</div>
                  </div>
                ))}
              </div>
            </div>

            {/* Strategies */}
            <div className="space-y-4">
              {growthPhases[activePhase].strategies.map((strategy) => (
                <motion.div
                  key={strategy.title}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  className="card-cyber p-4 hover:bg-surface-hover transition-colors"
                >
                  <h4 className="font-bold text-glow text-base mb-3">
                    {strategy.title}
                  </h4>
                  <div className="grid grid-cols-2 gap-3">
                    {strategy.points.map((point, index) => (
                      <div key={index} className="flex items-start gap-2">
                        <div className="w-5 h-5 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0 mt-0.5">
                          <span className="text-primary text-xs">{index + 1}</span>
                        </div>
                        <span className="text-text-secondary text-sm">{point}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            {/* Partnerships */}
            <div className="glass p-4 md:p-6 rounded-xl">
              <h3 className="text-xl font-bold mb-4">Strategic Partnerships</h3>
              <div className="grid grid-cols-2 gap-4">
                {currentPartnerships.map((category) => (
                  <div key={category.category}>
                    <h4 className="font-bold text-primary text-sm mb-2">
                      {category.category}
                    </h4>
                    <ul className="space-y-2">
                      {category.partners.map((partner, index) => (
                        <li key={index} className="flex items-center gap-1.5 text-text-secondary text-xs">
                          <ArrowRight size={12} className="text-primary flex-shrink-0" />
                          <span>{partner}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Growth Metrics - Only shown to investors */}
            {isInvestorView && (
              <div className="glass p-4 md:p-6 rounded-xl">
                <h3 className="text-xl font-bold mb-4">Growth Metrics</h3>
                <div className="grid grid-cols-2 gap-3">
                  <div className="card-cyber p-3">
                    <Users className="text-primary mb-2" size={18} />
                    <div className="font-bold text-glow text-sm mb-1">User Growth</div>
                    <div className="text-text-secondary text-xs">1400% in 3Y</div>
                  </div>
                  <div className="card-cyber p-3">
                    <TrendingUp className="text-primary mb-2" size={18} />
                    <div className="font-bold text-glow text-sm mb-1">Revenue</div>
                    <div className="text-text-secondary text-xs">2900% in 3Y</div>
                  </div>
                  <div className="card-cyber p-3">
                    <Target className="text-primary mb-2" size={18} />
                    <div className="font-bold text-glow text-sm mb-1">Coverage</div>
                    <div className="text-text-secondary text-xs">Global</div>
                  </div>
                  <div className="card-cyber p-3">
                    <Star className="text-primary mb-2" size={18} />
                    <div className="font-bold text-glow text-sm mb-1">Market Share</div>
                    <div className="text-text-secondary text-xs">5% Target</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default GrowthStrategy;