import React from 'react';
import { motion } from 'framer-motion';
import { 
  Check,
  X,
  Shield,
  Users,
  MessageCircle,
  Camera,
  Globe,
  Lock
} from 'lucide-react';
import { useTheme } from '../styles/theme';

const CompetitiveAnalysis = () => {
  const theme = useTheme();

  const competitors = [
    {
      name: "MakeFace",
      logo: "/assets/images/makeface.png",
      features: {
        "Multi-Profile System": true,
        "Anime Integration": true,
        "Anonymous Chat": true,
        "AI Security": true,
        "48post System": true,
        "Global Communities": true
      },
      highlights: [
        "First-mover in anime social networking",
        "Advanced privacy features",
        "Multiple identity management",
        "AI-powered security"
      ]
    },
    {
      name: "Traditional Social Media",
      logo: "/assets/images/traditional.jpg",
      features: {
        "Multi-Profile System": false,
        "Anime Integration": false,
        "Anonymous Chat": false,
        "AI Security": true,
        "48post System": true,
        "Global Communities": true
      },
      highlights: [
        "Large user base",
        "Established platform",
        "Basic privacy controls",
        "Limited anonymity"
      ]
    },
    {
      name: "Anime Forums",
      logo: "/assets/images/forums.jpg",
      features: {
        "Multi-Profile System": false,
        "Anime Integration": true,
        "Anonymous Chat": true,
        "AI Security": false,
        "48post System": false,
        "Global Communities": true
      },
      highlights: [
        "Anime-focused content",
        "Basic community features",
        "Limited social features",
        "Minimal privacy controls"
      ]
    }
  ];

  return (
    <section className="section-cyber">
      {/* Cyber Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Competitive Advantage</span>
          </h2>
          <p className="text-xl text-text-secondary max-w-2xl mx-auto">
            Setting new standards in social networking with unique features
          </p>
        </motion.div>

        {/* Comparison Table */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="glass p-8 rounded-xl mb-12 overflow-x-auto"
        >
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left p-4">Features</th>
                {competitors.map((competitor) => (
                  <th key={competitor.name} className="text-center p-4">
                    <div className="flex flex-col items-center gap-2">
                      <div className="w-12 h-12 rounded-full bg-surface-light overflow-hidden flex items-center justify-center">
                        <img
                          src={competitor.logo}
                          alt={competitor.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <span className="font-bold text-glow">{competitor.name}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(competitors[0].features).map((feature) => (
                <tr key={feature} className="border-t border-primary/20">
                  <td className="p-4 font-semibold text-glow">{feature}</td>
                  {competitors.map((competitor) => (
                    <td key={`${competitor.name}-${feature}`} className="text-center p-4">
                      {competitor.features[feature] ? (
                        <div className="w-8 h-8 rounded-full bg-success/20 flex items-center justify-center mx-auto">
                          <Check className="text-success" size={20} />
                        </div>
                      ) : (
                        <div className="w-8 h-8 rounded-full bg-error/20 flex items-center justify-center mx-auto">
                          <X className="text-error" size={20} />
                        </div>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </motion.div>

        {/* Unique Features */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="card-cyber p-6 hover-glow"
          >
            <Shield className="text-primary mb-4" size={24} />
            <h3 className="font-bold text-glow mb-2">Enhanced Privacy</h3>
            <p className="text-text-secondary">
              AI-powered security and multi-layered privacy controls
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="card-cyber p-6 hover-glow"
          >
            <Users className="text-primary mb-4" size={24} />
            <h3 className="font-bold text-glow mb-2">Multi-Profile System</h3>
            <p className="text-text-secondary">
              Express different personas securely and seamlessly
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="card-cyber p-6 hover-glow"
          >
            <MessageCircle className="text-primary mb-4" size={24} />
            <h3 className="font-bold text-glow mb-2">Anime Integration</h3>
            <p className="text-text-secondary">
              Unique anime-themed social experience
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="card-cyber p-6 hover-glow"
          >
            <Globe className="text-primary mb-4" size={24} />
            <h3 className="font-bold text-glow mb-2">Global Communities</h3>
            <p className="text-text-secondary">
              Connect with anime fans worldwide
            </p>
          </motion.div>
        </div>

        {/* Market Position */}
        <div className="grid lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="glass p-8 rounded-xl"
          >
            <h3 className="cyber-subheading mb-6">Market Position</h3>
            <div className="space-y-6">
              {competitors.map((competitor) => (
                <div key={competitor.name}>
                  <div className="flex items-center gap-4 mb-4">
                    <div className="w-12 h-12 rounded-full bg-surface-light overflow-hidden">
                      <img
                        src={competitor.logo}
                        alt={competitor.name}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <h4 className="font-bold text-glow">{competitor.name}</h4>
                  </div>
                  <ul className="space-y-2">
                    {competitor.highlights.map((highlight, index) => (
                      <li key={index} className="flex items-center gap-2 text-text-secondary">
                        <div className="w-1.5 h-1.5 rounded-full bg-primary" />
                        {highlight}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="glass p-8 rounded-xl"
          >
            <h3 className="cyber-subheading mb-6">Our Advantages</h3>
            <div className="space-y-6">
              <motion.div
                className="card-cyber p-6 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <h4 className="font-bold text-glow mb-4">First Mover Advantage</h4>
                <p className="text-text-secondary">
                  Pioneering the integration of anime culture with social networking
                </p>
              </motion.div>

              <motion.div
                className="card-cyber p-6 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <h4 className="font-bold text-glow mb-4">Technology Edge</h4>
                <p className="text-text-secondary">
                  Advanced AI-powered features and multi-profile management system
                </p>
              </motion.div>

              <motion.div
                className="card-cyber p-6 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <h4 className="font-bold text-glow mb-4">Market Understanding</h4>
                <p className="text-text-secondary">
                  Deep integration with anime community needs and preferences
                </p>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default CompetitiveAnalysis;