import React from 'react';
import { motion } from 'framer-motion';
import { 
  TrendingUp, 
  Users, 
  DollarSign, 
  Target,
  Globe
} from 'lucide-react';
import { useTheme } from '../styles/theme';
import { useView } from './ViewToggle';

const MarketOpportunity = () => {
  const theme = useTheme();
  const { isInvestorView } = useView();

  // Public metrics (visible to all)
  const publicStats = [
    {
      title: "Active Users",
      value: "5.17B",
      growth: "Global Users",
      description: "Active social media users worldwide",
      icon: Users
    },
    {
      title: "Market Growth",
      value: "13.2%",
      growth: "Annual Growth",
      description: "Year-over-year market expansion",
      icon: TrendingUp
    },
    {
      title: "Global Reach",
      value: "100+",
      growth: "Countries",
      description: "International market presence",
      icon: Globe
    }
  ];

  // Investor-only metrics
  const investorStats = [
    {
      title: "Global Social Media Market",
      value: "$251.45B",
      growth: "+13.2% CAGR",
      description: "Projected market size by 2024",
      icon: TrendingUp
    },
    {
      title: "Anime Market Size",
      value: "$32.3B",
      growth: "+8.73% CAGR",
      description: "Expected to reach $49.1B by 2029",
      icon: DollarSign
    },
    {
      title: "Target Audience",
      value: "517M-825M",
      growth: "Users",
      description: "Potential user base from social media & anime fans",
      icon: Target
    },
    {
      title: "Active Social Media Users",
      value: "5.17B",
      growth: "Global Users",
      description: "63.7% of global population",
      icon: Users
    }
  ];

  // Market segments shown to investors
  const investorMarketSegments = [
    {
      name: "Gen Z & Millennials",
      percentage: 63,
      description: "Primary target demographic",
      growth: "+15.2% YoY"
    },
    {
      name: "Anime Enthusiasts",
      percentage: 87,
      description: "High engagement potential",
      growth: "+23.4% YoY"
    },
    {
      name: "Content Creators",
      percentage: 42,
      description: "Platform contributors",
      growth: "+18.7% YoY"
    }
  ];

  // Public market segments
  const publicMarketSegments = [
    {
      name: "Anime Community",
      percentage: 85,
      description: "Active community engagement",
      growth: "Growing"
    },
    {
      name: "Social Connection",
      percentage: 90,
      description: "Platform interaction rate",
      growth: "Active"
    }
  ];

  return (
    <section className="section-cyber">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Section Header */}
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Market Opportunity</span>
          </h2>
          <p className="text-xl text-text-secondary max-w-2xl mx-auto">
            {isInvestorView 
              ? "Targeting the intersection of social media and anime communities"
              : "Join the growing community of anime enthusiasts"
            }
          </p>
        </motion.div>

        {/* Stats Grid */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-20"
        >
          {(isInvestorView ? investorStats : publicStats).map((stat, index) => (
            <motion.div
              key={stat.title}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="card-cyber p-6 hover-glow"
            >
              <stat.icon className="text-primary mb-4" size={24} />
              <h3 className="text-2xl font-bold text-glow mb-2">{stat.value}</h3>
              <div className="text-primary font-semibold mb-2">{stat.growth}</div>
              <p className="text-text-secondary text-sm">{stat.description}</p>
            </motion.div>
          ))}
        </motion.div>

        {/* Market Analysis - Only shown to investors */}
        {isInvestorView && (
          <div className="grid lg:grid-cols-2 gap-12">
            {/* Market Segments */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="space-y-6"
            >
              <div className="glass p-8 rounded-xl">
                <h3 className="text-2xl font-bold mb-6">Target Segments</h3>
                {investorMarketSegments.map((segment, index) => (
                  <div key={segment.name} className="mb-8 last:mb-0">
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-glow">{segment.name}</span>
                      <span className="text-primary font-semibold">{segment.percentage}%</span>
                    </div>
                    <div className="relative h-2 bg-surface-light rounded-full overflow-hidden mb-2">
                      <motion.div
                        initial={{ width: 0 }}
                        whileInView={{ width: `${segment.percentage}%` }}
                        transition={{ duration: 1, delay: index * 0.2 }}
                        className="absolute h-full bg-gradient-to-r from-primary to-secondary rounded-full"
                      />
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-text-secondary">{segment.description}</span>
                      <span className="text-primary">{segment.growth}</span>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>

            {/* Revenue Potential - Investor Only */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              className="space-y-6"
            >
              <div className="glass p-8 rounded-xl">
                <h3 className="text-2xl font-bold mb-6">Revenue Potential</h3>
                <div className="grid grid-cols-2 gap-6">
                  <div className="card-cyber p-6">
                    <div className="text-3xl font-bold text-glow mb-2">$30-50</div>
                    <div className="text-text-secondary">Annual Revenue Per User</div>
                  </div>
                  <div className="card-cyber p-6">
                    <div className="text-3xl font-bold text-glow mb-2">1%</div>
                    <div className="text-text-secondary">Initial Market Penetration</div>
                  </div>
                  <div className="card-cyber p-6 col-span-2">
                    <div className="text-3xl font-bold text-glow mb-2">$1.55B-4.13B</div>
                    <div className="text-text-secondary">Projected Annual Revenue</div>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}

        {/* Public Market Segments - Shown to all users */}
        {!isInvestorView && (
          <div className="grid md:grid-cols-2 gap-6">
            {publicMarketSegments.map((segment, index) => (
              <motion.div
                key={segment.name}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="glass p-6 rounded-xl"
              >
                <h3 className="text-xl font-bold mb-4">{segment.name}</h3>
                <div className="relative h-2 bg-surface-light rounded-full overflow-hidden mb-4">
                  <motion.div
                    initial={{ width: 0 }}
                    whileInView={{ width: `${segment.percentage}%` }}
                    transition={{ duration: 1 }}
                    className="absolute h-full bg-gradient-to-r from-primary to-secondary rounded-full"
                  />
                </div>
                <p className="text-text-secondary">{segment.description}</p>
                <div className="text-primary font-semibold mt-2">{segment.growth}</div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default MarketOpportunity;