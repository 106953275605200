import React from 'react';
import { motion } from 'framer-motion';
import { 
  TwitterIcon, 
  LinkedinIcon, 
  InstagramIcon,
  ArrowUpIcon,
  Mail
} from 'lucide-react';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToSection = (section) => {
    const sections = document.getElementsByClassName('section-cyber');
    const sectionMap = {
      'features': 1,      // ProductDemo
      'security': 3,      // SecurityPrivacy
      'team': 8,         // FounderVision
      'investors': 2,     // MarketOpportunity
    };

    const index = sectionMap[section];
    if (index !== undefined && sections[index]) {
      sections[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const quickLinks = [
    {
      title: "Product",
      enabled: true,
      links: [
        { label: "Features", action: () => scrollToSection('features') },
        { label: "Security", action: () => scrollToSection('security') },
        { label: "Team", action: () => scrollToSection('team') },
        { label: "Investors", action: () => scrollToSection('investors') }
      ]
    },
    {
      title: "Company",
      enabled: false,
      links: [
        { label: "About Us", action: () => {} },
        { label: "Careers", action: () => {} },
        { label: "Contact", action: () => {} },
        { label: "Development", action: () => {} }
      ]
    },
    {
      title: "Resources",
      enabled: false,
      links: [
        { label: "Technology", action: () => {} },
        { label: "Privacy Policy", action: () => {} },
        { label: "Terms of Service", action: () => {} },
        { label: "Growth Strategy", action: () => {} }
      ]
    }
  ];

  const socialLinks = [
    { 
      icon: LinkedinIcon, 
      href: "https://www.linkedin.com/in/pratik-petkar-b9a8a8238/", 
      label: "LinkedIn",
      enabled: true
    },
    { 
      icon: Mail, 
      href: "mailto:makefacetechnologies@gmail.com", 
      label: "Email",
      enabled: true
    },
    { 
      icon: TwitterIcon, 
      href: "#", 
      label: "Twitter",
      enabled: false
    },
    { 
      icon: InstagramIcon, 
      href: "https://www.instagram.com/makeface.tech?igsh=MTJoMzA2eHd4N25qaQ==", 
      label: "Instagram",
      enabled: true
    }
  ];

  return (
    <footer className="relative bg-surface pt-20 pb-6 overflow-hidden">
      {/* Cyber Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 w-full h-px bg-gradient-to-r from-transparent via-primary to-transparent" />
      </div>

      <div className="container mx-auto px-4 relative">
        {/* Main Footer Content */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* Brand */}
          <div>
            <h3 className="text-2xl font-bold text-glow mb-4">MakeFace</h3>
            <p className="text-text-secondary mb-6">
              Revolutionizing social connectivity through anime-themed experiences.
            </p>
            <div className="flex gap-4">
              {socialLinks.map((social) => (
                <motion.a
                  key={social.label}
                  href={social.href}
                  className={`w-10 h-10 rounded-full bg-surface-light flex items-center justify-center text-text-secondary transition-all
                    ${social.enabled 
                      ? 'hover:bg-primary hover:text-white hover-glow cursor-pointer' 
                      : 'opacity-50 cursor-not-allowed'}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={social.label}
                  onClick={(e) => !social.enabled && e.preventDefault()}
                  whileHover={social.enabled ? { scale: 1.1 } : {}}
                >
                  <social.icon size={20} />
                </motion.a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          {quickLinks.map((section) => (
            <div key={section.title} className={section.enabled ? "" : "opacity-50"}>
              <h4 className="font-semibold text-glow mb-4 flex items-center gap-2">
                {section.title}
                {!section.enabled && (
                  <span className="text-xs px-2 py-0.5 rounded-full bg-surface-light text-text-secondary">
                    Coming Soon
                  </span>
                )}
              </h4>
              <ul className="space-y-2">
                {section.links.map((link) => (
                  <li key={link.label}>
                    <button 
                      onClick={link.action}
                      disabled={!section.enabled}
                      className={`text-text-secondary hover:text-primary hover:text-glow transition-colors flex items-center gap-2 group ${
                        !section.enabled ? 'cursor-not-allowed' : 'cursor-pointer'
                      }`}
                    >
                      <div className={`w-1.5 h-1.5 rounded-full ${
                        section.enabled ? 'bg-primary/50 group-hover:bg-primary' : 'bg-text-secondary'
                      } transition-colors`} />
                      {link.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Contact Info */}
        <div className="grid md:grid-cols-2 gap-8 py-8 border-t border-b border-primary/20">
          <motion.div 
            className="card-cyber p-4"
            whileHover={{ scale: 1.02 }}
          >
            <h4 className="font-semibold text-glow mb-2">Address</h4>
            <address className="text-text-secondary not-italic">
              Ganganagar, Katol road, Nagpur
            </address>
          </motion.div>
          
          <motion.div 
            className="card-cyber p-4"
            whileHover={{ scale: 1.02 }}
          >
            <h4 className="font-semibold text-glow mb-2">Contact</h4>
            <a 
              href="mailto:makefacetechnologies@gmail.com"
              className="text-text-secondary hover:text-primary hover:text-glow transition-colors"
            >
              makefacetechnologies@gmail.com
            </a>
          </motion.div>
        </div>

        {/* Bottom Bar */}
        <div className="flex flex-wrap justify-between items-center pt-6">
          <p className="text-text-secondary text-sm">
            © {new Date().getFullYear()} MakeFace. All rights reserved.
          </p>
          <motion.button
            onClick={scrollToTop}
            className="w-10 h-10 rounded-full bg-surface-light flex items-center justify-center text-text-secondary hover:bg-primary hover:text-white hover-glow transition-all"
            aria-label="Scroll to top"
            whileHover={{ scale: 1.1, y: -2 }}
          >
            <ArrowUpIcon size={20} />
          </motion.button>
        </div>

        {/* Bottom Gradient Line */}
        <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-primary to-transparent" />
      </div>

      {/* Corner Decorations */}
      <div className="absolute bottom-0 right-0 w-32 h-32 bg-gradient-radial from-primary/10 to-transparent opacity-30" />
      <div className="absolute bottom-0 left-0 w-32 h-32 bg-gradient-radial from-secondary/10 to-transparent opacity-30" />
    </footer>
  );
};

export default Footer;