import React from 'react';
import { motion } from 'framer-motion';
import { 
  Target, 
  Star, 
  Heart, 
  Shield, 
  Rocket,
  Users,
  Puzzle,
  Zap,
  Award
} from 'lucide-react';
import { useTheme } from '../styles/theme';
import { useView } from './ViewToggle';

const FounderVision = () => {
  const theme = useTheme();
  const { isInvestorView } = useView();

  // Updated founders data with corrected image paths for investor view
  const founders = {
    public: [
      {
        name: "Pratik Petkar",
        role: "Founder",
        expertise: "Software Engineer & Business Management",
        image: "/assets/images/pratik.jpg",
        quote: "Revolutionizing social connectivity through anime culture",
        responsibilities: [
          "Product vision",
          "Business development",
          "Team leadership"
        ]
      },
      {
        name: "Zeeshan Ahmed",
        role: "Co-founder",
        expertise: "Software Engineer & UI/UX Designer",
        image: "/assets/images/zeeshan.jpg",
        quote: "Creating seamless and engaging user experiences",
        responsibilities: [
          "User experience",
          "Design system",
          "Frontend architecture"
        ]
      },
      {
        name: "Abhishek Hiwarkar",
        role: "Co-founder",
        expertise: "Software Engineer & Technical Operations",
        image: "/assets/images/abhishek.jpg",
        quote: "Building robust and scalable infrastructure",
        responsibilities: [
          "Backend development",
          "Infrastructure",
          "Security"
        ]
      },
      {
        name: "Prajwal Sontakke",
        role: "Co-founder",
        expertise: "Software Engineer & Business Operations",
        image: "/assets/images/prajwal.jpg",
        quote: "Optimizing operations and business processes",
        responsibilities: [
          "Operations",
          "Project management",
          "Partnerships"
        ]
      },
      {
        name: "Apeksha Meshram",
        role: "Co-founder",
        expertise: "Software Developer",
        image: "/assets/images/apeksha.jpg",
        quote: "Building innovative solutions",
        responsibilities: [
          "Software development",
          "Feature implementation",
          "Application testing"
        ]
      },
      {
        name: "Manish Umarvaishya",
        role: "Co-founder",
        expertise: "Software Developer",
        image: "/assets/images/manish.jpg",
        quote: "Creating scalable solutions",
        responsibilities: [
          "Software development",
          "Code optimization",
          "Technical implementation"
        ]
      }
    ],
    investor: [
      {
        name: "Pratik Petkar",
        role: "Founder",
        equity: "53%",
        expertise: "Software Engineer & Business Management",
        image: "/assets/images/pratik.jpg", // Fixed extension
        quote: "Revolutionizing social connectivity through anime culture",
        responsibilities: [
          "Overall vision & strategy",
          "Business development",
          "Product roadmap",
          "Investor relations"
        ]
      },
      {
        name: "Zeeshan Ahmed",
        role: "Co-founder",
        equity: "15%",
        expertise: "Software Engineer & UI/UX Designer",
        image: "/assets/images/zeeshan.jpg", // Fixed path and extension
        quote: "Creating seamless and engaging user experiences",
        responsibilities: [
          "UI/UX design",
          "Front-end architecture",
          "Design system",
          "User experience"
        ]
      },
      {
        name: "Abhishek Hiwarkar",
        role: "Co-founder",
        equity: "15%",
        expertise: "Software Engineer & Technical Operations",
        image: "/assets/images/abhishek.jpg", // Fixed extension
        quote: "Building robust and scalable infrastructure",
        responsibilities: [
          "Backend architecture",
          "Technical operations",
          "Infrastructure",
          "Security"
        ]
      },
      {
        name: "Prajwal Sontakke",
        role: "Co-founder",
        equity: "15%",
        expertise: "Software Engineer & Business Operations",
        image: "/assets/images/prajwal.jpg", // Fixed extension
        quote: "Optimizing operations and business processes",
        responsibilities: [
          "Business operations",
          "Project management",
          "Team coordination",
          "Partnerships"
        ]
      },
      {
        name: "Apeksha Meshram",
        role: "Co-founder",
        equity: "1%",
        expertise: "Software Developer",
        image: "/assets/images/apeksha.jpg", // Fixed extension
        quote: "Building innovative solutions",
        responsibilities: [
          "Software development",
          "Feature implementation",
          "Application testing",
          "Quality assurance"
        ]
      },
      {
        name: "Manish Umarvaishya",
        role: "Co-founder",
        equity: "1%",
        expertise: "Software Developer",
        image: "/assets/images/manish.jpg", // Fixed extension
        quote: "Creating scalable solutions",
        responsibilities: [
          "Software development",
          "Code optimization",
          "Technical implementation",
          "System architecture"
        ]
      }
    ]
  };

  // Rest of the code remains exactly the same
  const coreValues = [
    {
      title: "Innovation",
      icon: Rocket,
      description: "Pushing boundaries in social networking and anime integration"
    },
    {
      title: "Privacy",
      icon: Shield,
      description: "Prioritizing user privacy and data protection"
    },
    {
      title: "Community",
      icon: Users,
      description: "Building meaningful connections and relationships"
    },
    {
      title: "Excellence",
      icon: Star,
      description: "Striving for the highest quality in everything we do"
    }
  ];

  const vision = {
    public: {
      shortTerm: [
        "Community growth",
        "Feature launches",
        "User engagement",
        "Platform stability"
      ],
      midTerm: [
        "Global expansion",
        "Enhanced features",
        "Creator tools",
        "Community events"
      ],
      longTerm: [
        "Industry leadership",
        "Innovation",
        "Global presence",
        "Community impact"
      ]
    },
    investor: {
      shortTerm: [
        "Launch in major anime markets",
        "Achieve 2M+ active users",
        "Establish key partnerships",
        "Release core features"
      ],
      midTerm: [
        "Expand to 10+ countries",
        "Reach 10M+ active users",
        "Launch premium features",
        "Develop creator ecosystem"
      ],
      longTerm: [
        "Global market presence",
        "30M+ active users",
        "Industry leadership",
        "Platform innovation"
      ]
    }
  };

  const currentVision = isInvestorView ? vision.investor : vision.public;
  const currentFounders = isInvestorView ? founders.investor : founders.public;

  return (
    <section className="section-cyber">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Our Vision & Team</span>
          </h2>
          <p className="text-xl text-text-secondary max-w-2xl mx-auto">
            Meet the innovators shaping the future of social connectivity
          </p>
        </motion.div>

        {/* Founding Team */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-20">
          {currentFounders.map((founder, index) => (
            <motion.div
              key={founder.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="card-cyber overflow-hidden hover-glow"
              whileHover={{ scale: 1.02 }}
            >
              {/* Profile Image */}
              <div className="relative aspect-square">
                <img
                  src={founder.image}
                  alt={founder.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-surface to-transparent" />
              </div>

              {/* Profile Info */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-glow mb-1">{founder.name}</h3>
                <div className="text-primary font-semibold mb-2">{founder.role}</div>
                <p className="text-text-secondary text-sm mb-4">{founder.expertise}</p>

                {/* Responsibilities */}
                <div className="space-y-2 mb-4">
                  {founder.responsibilities.map((responsibility, index) => (
                    <div key={index} className="flex items-center gap-2 text-sm">
                      <div className="w-1.5 h-1.5 rounded-full bg-primary" />
                      <span className="text-text-secondary">{responsibility}</span>
                    </div>
                  ))}
                </div>

                {/* Equity - Only shown to investors */}
                {isInvestorView && (
                  <div className="glass p-3 rounded-lg">
                    <div className="text-sm text-text-secondary">Equity Stake</div>
                    <div className="text-xl font-bold text-primary">{founder.equity}</div>
                  </div>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Core Values */}
        <div className="grid md:grid-cols-4 gap-6 mb-20">
          {coreValues.map((value, index) => (
            <motion.div
              key={value.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="card-cyber p-6 hover-glow"
              whileHover={{ scale: 1.02 }}
            >
              <value.icon className="text-primary mb-4" size={24} />
              <h4 className="font-bold text-glow mb-2">{value.title}</h4>
              <p className="text-text-secondary text-sm">{value.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Vision Timeline */}
        <div className="grid lg:grid-cols-3 gap-6">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="glass p-6 rounded-xl"
          >
            <h4 className="font-bold text-primary mb-4">Short-term Goals</h4>
            <div className="space-y-2">
              {currentVision.shortTerm.map((goal, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Target size={16} className="text-primary" />
                  <span className="text-text-secondary text-sm">{goal}</span>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="glass p-6 rounded-xl"
          >
            <h4 className="font-bold text-primary mb-4">Mid-term Goals</h4>
            <div className="space-y-2">
              {currentVision.midTerm.map((goal, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Puzzle size={16} className="text-primary" />
                  <span className="text-text-secondary text-sm">{goal}</span>
                </div>
              ))}
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="glass p-6 rounded-xl"
          >
            <h4 className="font-bold text-primary mb-4">Long-term Vision</h4>
            <div className="space-y-2">
              {currentVision.longTerm.map((goal, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Star size={16} className="text-primary" />
                  <span className="text-text-secondary text-sm">{goal}</span>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default FounderVision;