import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  Database,
  Cloud,
  Shield,
  Server,
  Smartphone,
  Code,
  Zap,
  Globe,
  Cpu,
  Lock
} from 'lucide-react';

const TechnologyStack = () => {
  const [activeLayer, setActiveLayer] = useState('mobile');

  const techStack = {
    mobile: {
      title: "Mobile",
      fullTitle: "Flutter Development",
      description: "Cross-platform mobile application built with Flutter & Dart",
      icon: Smartphone,
      technologies: [
        {
          name: "Flutter",
          type: "UI Framework",
          details: "Google's UI toolkit for building natively compiled applications",
          features: [
            "Cross-platform development",
            "Hot reload functionality",
            "Rich widget library",
            "Native performance"
          ]
        },
        {
          name: "Dart",
          type: "Programming Language",
          details: "Object-oriented language optimized for UI",
          features: [
            "Sound null safety",
            "Async/await support",
            "Strong typing",
            "JIT & AOT compilation"
          ]
        },
        {
          name: "State Management",
          type: "Application Logic",
          details: "Efficient state handling in Flutter",
          features: [
            "Provider pattern",
            "Stream management",
            "State persistence",
            "Reactive programming"
          ]
        }
      ],
      metrics: {
        performance: 98,
        reliability: 99.9,
        scalability: 95
      }
    },
    backend: {
      title: "Backend",
      fullTitle: "Backend Services",
      description: "Serverless architecture with Firebase & Google Cloud Platform",
      icon: Cloud,
      technologies: [
        {
          name: "Firebase",
          type: "Backend as a Service",
          details: "Google's mobile and web application platform",
          features: [
            "Realtime Database",
            "Cloud Firestore",
            "Firebase Auth",
            "Cloud Storage"
          ]
        },
        {
          name: "Google Cloud Platform",
          type: "Cloud Infrastructure",
          details: "Enterprise-grade cloud computing services",
          features: [
            "Cloud Functions",
            "App Engine",
            "Cloud Storage",
            "Cloud Messaging"
          ]
        },
        {
          name: "Node.js",
          type: "Server Operations",
          details: "Server-side JavaScript runtime",
          features: [
            "REST API endpoints",
            "Background jobs",
            "Data processing",
            "Microservices"
          ]
        }
      ],
      metrics: {
        performance: 96,
        reliability: 99.99,
        scalability: 98
      }
    },
    security: {
      title: "Security",
      fullTitle: "Security & Authentication",
      description: "Enterprise-grade security with Firebase Authentication",
      icon: Shield,
      technologies: [
        {
          name: "Firebase Auth",
          type: "Authentication",
          details: "Complete authentication system",
          features: [
            "Email/Password auth",
            "Google Sign-in",
            "Phone authentication",
            "Custom auth tokens"
          ]
        },
        {
          name: "Cloud Security",
          type: "Infrastructure Security",
          details: "Google Cloud Platform security features",
          features: [
            "Data encryption",
            "Identity management",
            "Security monitoring",
            "Access controls"
          ]
        },
        {
          name: "App Security",
          type: "Application Security",
          details: "Flutter & Firebase security integration",
          features: [
            "Secure storage",
            "SSL pinning",
            "App signing",
            "Proguard rules"
          ]
        }
      ],
      metrics: {
        performance: 94,
        reliability: 99.999,
        scalability: 97
      }
    }
  };

  return (
    <section className="section-cyber py-12 md:py-20">
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-16 px-4 md:px-0"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Technology Stack</span>
          </h2>
          <p className="text-lg md:text-xl text-text-secondary max-w-2xl mx-auto">
            Modern cross-platform development powered by Flutter, Firebase & Google Cloud
          </p>
        </motion.div>

        {/* Technology Navigation - Mobile Scroll */}
        <div className="overflow-x-auto mb-8 md:mb-12 px-4 -mx-4 md:mx-0 md:px-0">
          <div className="flex md:justify-center min-w-min md:min-w-0 gap-2 md:gap-4 px-4 md:px-0">
            {Object.entries(techStack).map(([key, stack]) => (
              <button
                key={key}
                onClick={() => setActiveLayer(key)}
                className={`whitespace-nowrap flex items-center gap-2 px-4 md:px-6 py-3 rounded-lg transition-all text-sm md:text-base
                  ${activeLayer === key ? 'btn-primary' : 'btn-secondary'}`}
              >
                <stack.icon size={20} />
                <span className="hidden md:inline">{stack.fullTitle}</span>
                <span className="md:hidden">{stack.title}</span>
              </button>
            ))}
          </div>
        </div>

        <motion.div
          key={activeLayer}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid lg:grid-cols-2 gap-8 px-4 md:px-0"
        >
          {/* Technology Details */}
          <div>
            <h3 className="cyber-subheading mb-4">
              {techStack[activeLayer].fullTitle}
            </h3>
            <p className="text-text-secondary text-sm md:text-base mb-6">
              {techStack[activeLayer].description}
            </p>

            <div className="space-y-4">
              {techStack[activeLayer].technologies.map((tech) => (
                <motion.div
                  key={tech.name}
                  className="card-cyber p-4 md:p-6 hover-glow"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className="flex items-start gap-4 mb-3">
                    <div className="w-10 h-10 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                      <Code className="text-primary" size={20} />
                    </div>
                    <div>
                      <h4 className="font-bold text-glow text-base md:text-lg">{tech.name}</h4>
                      <p className="text-text-secondary text-xs md:text-sm">{tech.type}</p>
                    </div>
                  </div>
                  <p className="text-text-secondary text-sm mb-3">{tech.details}</p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-3">
                    {tech.features.map((feature, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <div className="w-1.5 h-1.5 rounded-full bg-primary flex-shrink-0" />
                        <span className="text-text-secondary text-sm">{feature}</span>
                      </div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Metrics & Features */}
          <div className="space-y-6 md:space-y-8">
            {/* Performance Metrics */}
            <div className="glass p-4 md:p-6 rounded-xl">
              <h3 className="text-lg md:text-xl font-bold mb-4">Performance Metrics</h3>
              <div className="space-y-4">
                {Object.entries(techStack[activeLayer].metrics).map(([key, value]) => (
                  <div key={key}>
                    <div className="flex justify-between items-center mb-2">
                      <span className="text-text-secondary capitalize text-sm md:text-base">{key}</span>
                      <span className="text-primary font-bold text-sm md:text-base">{value}%</span>
                    </div>
                    <div className="w-full bg-surface-light rounded-full h-2">
                      <div
                        className="bg-gradient-to-r from-primary to-secondary rounded-full h-full transition-all duration-1000"
                        style={{ width: `${value}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Key Features */}
            <div className="grid grid-cols-2 gap-3 md:gap-4">
              <motion.div 
                className="card-cyber p-4 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <Zap className="text-primary mb-2" size={20} />
                <h4 className="font-bold text-glow text-sm md:text-base mb-1">Flutter Power</h4>
                <p className="text-text-secondary text-xs md:text-sm">
                  Fast development with hot reload
                </p>
              </motion.div>

              <motion.div 
                className="card-cyber p-4 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <Globe className="text-primary mb-2" size={20} />
                <h4 className="font-bold text-glow text-sm md:text-base mb-1">Cross Platform</h4>
                <p className="text-text-secondary text-xs md:text-sm">
                  Single Dart codebase for all platforms
                </p>
              </motion.div>

              <motion.div 
                className="card-cyber p-4 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <Cloud className="text-primary mb-2" size={20} />
                <h4 className="font-bold text-glow text-sm md:text-base mb-1">Firebase & GCP</h4>
                <p className="text-text-secondary text-xs md:text-sm">
                  Powerful cloud infrastructure
                </p>
              </motion.div>

              <motion.div 
                className="card-cyber p-4 hover-glow"
                whileHover={{ scale: 1.02 }}
              >
                <Lock className="text-primary mb-2" size={20} />
                <h4 className="font-bold text-glow text-sm md:text-base mb-1">Secure</h4>
                <p className="text-text-secondary text-xs md:text-sm">
                  Firebase Authentication & Security
                </p>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default TechnologyStack;