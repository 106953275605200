import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Lock, AlertCircle, CheckCircle } from 'lucide-react';
import { doc, setDoc, serverTimestamp, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

const InvestorAuth = ({ isOpen, onClose, onSuccess }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [status, setStatus] = useState('initial'); // initial, pending, confirmed, rejected

  const checkExistingAccess = async (email) => {
    try {
      const q = query(collection(db, 'investor_access'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        return data.status;
      }
      return null;
    } catch (error) {
      console.error('Error checking access:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Check existing access
      const existingStatus = await checkExistingAccess(email);

      if (existingStatus === 'confirmed') {
        // Already confirmed, grant access
        setStatus('confirmed');
        await logAccess('accessed');
        onSuccess();
        return;
      } else if (existingStatus === 'pending') {
        setStatus('pending');
        setError('Your request is pending confirmation. Please check back later.');
        return;
      } else if (existingStatus === 'rejected') {
        setError('Your access request has been declined.');
        return;
      }

      // Create new request
      const investorAccessRef = doc(collection(db, 'investor_access'));
      await setDoc(investorAccessRef, {
        name,
        email,
        company,
        role,
        status: 'pending',
        accessTimestamp: serverTimestamp(),
        ipAddress: await fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => data.ip),
        userAgent: navigator.userAgent,
        createdAt: serverTimestamp()
      });

      await logAccess('requested');
      setStatus('pending');
      
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const logAccess = async (action) => {
    try {
      await setDoc(doc(collection(db, 'investor_access_logs')), {
        name,
        email,
        company,
        role,
        timestamp: serverTimestamp(),
        ipAddress: await fetch('https://api.ipify.org?format=json').then(res => res.json()).then(data => data.ip),
        userAgent: navigator.userAgent,
        action: action
      });
    } catch (error) {
      console.error('Error logging access:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4"
        onClick={(e) => {
          if (e.target === e.currentTarget) onClose();
        }}
      >
        <motion.div
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.95, opacity: 0 }}
          className="glass max-w-md w-full rounded-xl p-6 relative"
          onClick={e => e.stopPropagation()}
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-text-secondary hover:text-white transition-colors"
          >
            <X size={24} />
          </button>

          {/* Header */}
          <div className="text-center mb-6">
            <div className="w-16 h-16 bg-primary/20 rounded-full flex items-center justify-center mx-auto mb-4">
              <Lock className="text-primary" size={32} />
            </div>
            <h2 className="text-2xl font-bold text-glow mb-2">Investor Access</h2>
            <p className="text-text-secondary">
              Please provide your details to access investor information
            </p>
          </div>

          {status === 'pending' ? (
            <div className="text-center py-8">
              <div className="w-16 h-16 bg-warning/20 rounded-full flex items-center justify-center mx-auto mb-4">
                <AlertCircle className="text-warning" size={32} />
              </div>
              <h3 className="text-xl font-bold mb-2">Access Pending</h3>
              <p className="text-text-secondary mb-4">
                Your request is being reviewed. Please check back later.
              </p>
              <button
                onClick={onClose}
                className="btn-secondary"
              >
                Close
              </button>
            </div>
          ) : status === 'confirmed' ? (
            <div className="text-center py-8">
              <div className="w-16 h-16 bg-success/20 rounded-full flex items-center justify-center mx-auto mb-4">
                <CheckCircle className="text-success" size={32} />
              </div>
              <h3 className="text-xl font-bold mb-2">Access Granted</h3>
              <p className="text-text-secondary mb-4">
                You now have access to investor information.
              </p>
              <button
                onClick={() => {
                  onSuccess();
                  onClose();
                }}
                className="btn-primary"
              >
                View Investor Information
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  required
                  className="input-cyber w-full"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your full name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1">
                  Business Email
                </label>
                <input
                  type="email"
                  required
                  className="input-cyber w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="name@company.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1">
                  Company Name
                </label>
                <input
                  type="text"
                  required
                  className="input-cyber w-full"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Your company name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-text-secondary mb-1">
                  Role
                </label>
                <input
                  type="text"
                  required
                  className="input-cyber w-full"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  placeholder="Your role at the company"
                />
              </div>

              {error && (
                <div className="bg-error/10 border border-error rounded-lg p-3 flex items-center gap-2">
                  <AlertCircle className="text-error" size={20} />
                  <span className="text-error text-sm">{error}</span>
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="btn-primary w-full flex items-center justify-center gap-2"
              >
                {loading ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    <span>Processing...</span>
                  </>
                ) : (
                  'Request Access'
                )}
              </button>
            </form>
          )}

          {/* Privacy Note */}
          <p className="text-text-secondary text-xs text-center mt-4">
            Your information will be securely stored and used only for verifying investor status.
          </p>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default InvestorAuth;