import React, { createContext, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { Eye, DollarSign } from 'lucide-react';
import InvestorAuth from './InvestorAuth';

const ViewContext = createContext();

export const useView = () => {
  const context = useContext(ViewContext);
  if (!context) {
    throw new Error('useView must be used within a ViewProvider');
  }
  return context;
};

export const ViewProvider = ({ children }) => {
  const [isInvestorView, setIsInvestorView] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showViewTransition, setShowViewTransition] = useState(false);

  const handleToggle = () => {
    if (!isInvestorView) {
      setShowAuth(true);
    } else {
      setShowViewTransition(true);
      setIsInvestorView(false);
      setTimeout(() => setShowViewTransition(false), 500);
    }
  };

  const handleAuthSuccess = () => {
    setShowAuth(false);
    setShowViewTransition(true);
    setIsInvestorView(true);
    setTimeout(() => setShowViewTransition(false), 500);
  };

  return (
    <ViewContext.Provider value={{ isInvestorView, handleToggle, showViewTransition }}>
      {children}
      <ViewToggleButton isInvestorView={isInvestorView} onClick={handleToggle} />
      <InvestorAuth 
        isOpen={showAuth} 
        onClose={() => setShowAuth(false)} 
        onSuccess={handleAuthSuccess}
      />
    </ViewContext.Provider>
  );
};

const ViewToggleButton = ({ isInvestorView, onClick }) => {
  return (
    <motion.button
      onClick={onClick}
      className="fixed bottom-4 right-4 btn-primary px-4 py-2 rounded-lg flex items-center gap-2 shadow-lg z-40 backdrop-blur-sm"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      {isInvestorView ? (
        <>
          <Eye size={20} />
          <span>Normal View</span>
        </>
      ) : (
        <>
          <DollarSign size={20} />
          <span>Investor View</span>
        </>
      )}
    </motion.button>
  );
};

export default ViewProvider;