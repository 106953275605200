import React from 'react';
import { motion } from 'framer-motion';
import { 
  DollarSign, 
  Target, 
  Users,
  Globe
} from 'lucide-react';
import { useView } from './ViewToggle';

const Investment = () => {
  const { isInvestorView } = useView();

  // If not in investor view, don't render anything
  if (!isInvestorView) {
    return null;
  }

  const fundingDetails = {
    amount: "3CR-10CR INR ($0.36M-$1.2M USD)",
    allocation: [
      {
        title: "Product Development",
        percentage: 40,
        details: [
          "AI algorithm enhancement",
          "Advanced anime features",
          "Security measures",
          "UI/UX improvements"
        ]
      },
      {
        title: "User Acquisition",
        percentage: 30,
        details: [
          "Digital marketing",
          "Influencer partnerships",
          "Community building",
          "Referral programs"
        ]
      },
      {
        title: "Operations",
        percentage: 20,
        details: [
          "Team expansion",
          "Infrastructure",
          "Legal compliance",
          "Office setup"
        ]
      },
      {
        title: "Marketing",
        percentage: 10,
        details: [
          "Brand awareness",
          "Content marketing",
          "Event sponsorships",
          "PR activities"
        ]
      }
    ]
  };

  const metrics = [
    {
      title: "User Growth",
      value: "30M+",
      timeline: "Year 3",
      icon: Users
    },
    {
      title: "Revenue Target",
      value: "$300M",
      timeline: "Year 3",
      icon: DollarSign
    },
    {
      title: "Market Share",
      value: "5%",
      timeline: "Year 3",
      icon: Target
    },
    {
      title: "Global Reach",
      value: "100+",
      timeline: "Countries",
      icon: Globe
    }
  ];

  return (
    <motion.section 
      className="section-cyber py-12 md:py-20"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-dots opacity-10" />
        <div className="absolute top-0 right-0 w-96 h-96 bg-gradient-radial from-primary/20 to-transparent opacity-30 animate-pulse" />
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-gradient-radial from-secondary/20 to-transparent opacity-30 animate-pulse" />
      </div>

      <div className="container relative">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-16 px-4 md:px-0"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Investment Opportunity</span>
          </h2>
          <p className="text-lg md:text-xl text-text-secondary max-w-2xl mx-auto">
            Join us in revolutionizing social connections through anime
          </p>
        </motion.div>

        {/* Funding Requirements */}
        <div className="grid lg:grid-cols-2 gap-8 md:gap-12 px-4 md:px-0">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="glass p-4 md:p-8 rounded-xl"
          >
            <h3 className="cyber-subheading mb-6">Funding Requirements</h3>
            <div className="card-cyber p-4 md:p-6 mb-6 md:mb-8">
              <div className="text-2xl md:text-4xl font-bold text-glow mb-2">
                {fundingDetails.amount}
              </div>
              <div className="text-text-secondary text-sm md:text-base">Seed Round Target</div>
            </div>

            <div className="space-y-6">
              {fundingDetails.allocation.map((item) => (
                <div key={item.title}>
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-semibold text-glow text-sm md:text-base">{item.title}</span>
                    <span className="text-primary text-sm md:text-base">{item.percentage}%</span>
                  </div>
                  <div className="w-full bg-surface-light rounded-full h-2 mb-2">
                    <div 
                      className="bg-gradient-to-r from-primary to-secondary rounded-full h-full transition-all duration-1000"
                      style={{ width: `${item.percentage}%` }}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-xs md:text-sm">
                    {item.details.map((detail, index) => (
                      <div key={index} className="flex items-center gap-2 text-text-secondary">
                        <div className="w-1.5 h-1.5 rounded-full bg-primary flex-shrink-0" />
                        <span>{detail}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </motion.div>

          {/* Growth Metrics */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            className="space-y-6 md:space-y-8"
          >
            <div className="glass p-4 md:p-8 rounded-xl">
              <h3 className="text-xl md:text-2xl font-bold mb-6">Growth Metrics</h3>
              <div className="grid grid-cols-2 gap-4">
                {metrics.map((metric) => (
                  <motion.div
                    key={metric.title}
                    className="card-cyber p-4 hover-glow"
                    whileHover={{ scale: 1.02 }}
                  >
                    <metric.icon className="text-primary mb-3" size={24} />
                    <div className="text-xl md:text-2xl font-bold text-glow mb-2">{metric.value}</div>
                    <div className="text-sm text-text-secondary mb-1">{metric.title}</div>
                    <div className="text-xs text-primary">{metric.timeline}</div>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default Investment;