import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { 
  Users, 
  MessageCircle, 
  Video, 
  Shield,
  ChevronRight,
  Lock,
  Settings,
  Globe,
  Play,
  Tv,
  Film,
  Star
} from 'lucide-react';

const ProductDemo = () => {
  const [activeFeature, setActiveFeature] = useState('multiProfile');

  const features = {
    multiProfile: {
      title: "Multi-Profile",
      fullTitle: "Multi-Profile System",
      description: "Express different personas securely with our unique profile management",
      icon: Users,
      demo: {
        profiles: [
          {
            type: "Main Profile",
            icon: Users,
            features: [
              "Professional networking",
              "Real identity",
              "Public content",
              "Standard social features"
            ]
          },
          {
            type: "Anime Profile",
            icon: MessageCircle,
            features: [
              "Anime avatar",
              "Anonymous interactions",
              "Themed content",
              "Community focus"
            ]
          },
          {
            type: "Circle Profile",
            icon: Globe,
            features: [
              "Interest-based groups",
              "Private communities",
              "Selective sharing",
              "Focused discussions"
            ]
          }
        ]
      }
    },
    animeChat: {
      title: "Anime Chat",
      fullTitle: "Anime Chat System",
      description: "Anonymous chat with anime-themed avatars and enhanced privacy",
      icon: MessageCircle,
      demo: {
        features: [
          {
            title: "Anonymous Mode",
            description: "Chat with complete privacy",
            icon: Lock
          },
          {
            title: "Theme Integration",
            description: "Anime-inspired chat interface",
            icon: Settings
          },
          {
            title: "Global Connection",
            description: "Connect with anime fans worldwide",
            icon: Globe
          }
        ]
      }
    },
    animeChannel: {
      title: "Channels",
      fullTitle: "Anime Channel",
      description: "Create and share anime content through personalized channels",
      icon: Tv,
      demo: {
        features: [
          {
            title: "Content Creator Hub",
            description: "Platform for anime content creators",
            icon: Film,
            details: [
              "Video upload and management",
              "Analytics dashboard",
              "Audience engagement tools",
              "Monetization options"
            ]
          },
          {
            title: "Industry Showcase",
            description: "Dedicated space for animation studios",
            icon: Star,
            details: [
              "Official announcements",
              "Behind-the-scenes content",
              "Exclusive premieres",
              "Fan engagement"
            ]
          },
          {
            title: "Anime Shorts",
            description: "Short-form video distribution",
            icon: Play,
            details: [
              "Algorithmic recommendations",
              "Trending content feed",
              "Interactive features",
              "Community sharing"
            ]
          }
        ]
      }
    }
  };

  return (
    <section className="section-cyber min-h-screen py-12 md:py-20">
      {/* Background elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 cyber-stripes opacity-10" />
        <div className="absolute top-0 w-full h-px bg-gradient-to-r from-transparent via-primary to-transparent" />
        <div className="absolute bottom-0 w-full h-px bg-gradient-to-r from-transparent via-primary to-transparent" />
      </div>

      <div className="container relative">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-8 md:mb-16"
        >
          <h2 className="cyber-heading mb-4">
            <span className="gradient-text">Product Features</span>
          </h2>
          <p className="text-text-secondary text-lg md:text-xl max-w-2xl mx-auto px-4">
            Experience the next generation of social networking
          </p>
        </motion.div>

        {/* Feature Navigation - Mobile Scroll */}
        <div className="overflow-x-auto mb-8 md:mb-12 px-4 md:px-0 -mx-4 md:mx-0">
          <div className="flex md:justify-center min-w-min md:min-w-0 gap-2 md:gap-4 px-4 md:px-0">
            {Object.entries(features).map(([key, feature]) => (
              <button
                key={key}
                onClick={() => setActiveFeature(key)}
                className={`whitespace-nowrap flex items-center gap-2 px-4 md:px-6 py-3 rounded-lg transition-all text-sm md:text-base
                  ${activeFeature === key ? 'btn-primary' : 'btn-secondary'}`}
              >
                <feature.icon size={20} />
                <span className="hidden md:inline">{feature.fullTitle}</span>
                <span className="md:hidden">{feature.title}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Feature Demo Area */}
        <motion.div
          key={activeFeature}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="grid lg:grid-cols-2 gap-8 md:gap-12 px-4 md:px-0"
        >
          {/* Feature Description */}
          <div className="space-y-6 md:space-y-8">
            <div>
              <h3 className="cyber-subheading mb-4">{features[activeFeature].fullTitle}</h3>
              <p className="text-text-secondary text-base md:text-lg">
                {features[activeFeature].description}
              </p>
            </div>

            {/* Multi-Profile Demo */}
            {activeFeature === 'multiProfile' && (
              <div className="space-y-4">
                {features.multiProfile.demo.profiles.map((profile) => (
                  <motion.div
                    key={profile.type}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className="card-cyber p-4 md:p-6 hover-glow"
                  >
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-primary/20 flex items-center justify-center">
                        <profile.icon className="text-primary" size={20} />
                      </div>
                      <h4 className="text-glow text-base md:text-lg">{profile.type}</h4>
                    </div>
                    <ul className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
                      {profile.features.map((feature, index) => (
                        <li key={index} className="flex items-center gap-2 text-text-secondary text-sm md:text-base">
                          <ChevronRight size={16} className="text-primary flex-shrink-0" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                ))}
              </div>
            )}

            {/* Anime Chat Demo */}
            {activeFeature === 'animeChat' && (
              <div className="space-y-4">
                {features.animeChat.demo.features.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="card-cyber p-4 md:p-6 hover-glow"
                  >
                    <div className="flex items-center gap-4">
                      <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                        <feature.icon className="text-primary" size={20} />
                      </div>
                      <div>
                        <h4 className="font-bold text-glow text-base md:text-lg mb-1">{feature.title}</h4>
                        <p className="text-text-secondary text-sm md:text-base">{feature.description}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}

            {/* Anime Channel Demo */}
            {activeFeature === 'animeChannel' && (
              <div className="space-y-4 md:space-y-6">
                {features.animeChannel.demo.features.map((feature, index) => (
                  <motion.div
                    key={feature.title}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="card-cyber p-4 md:p-6 hover-glow"
                  >
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-primary/20 flex items-center justify-center flex-shrink-0">
                        <feature.icon className="text-primary" size={20} />
                      </div>
                      <div>
                        <h4 className="font-bold text-glow text-base md:text-lg mb-1">{feature.title}</h4>
                        <p className="text-text-secondary text-sm md:text-base">{feature.description}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
                      {feature.details.map((detail, idx) => (
                        <div key={idx} className="flex items-center gap-2 text-text-secondary text-sm md:text-base">
                          <ChevronRight size={16} className="text-primary flex-shrink-0" />
                          <span>{detail}</span>
                        </div>
                      ))}
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </div>

          {/* Interactive Preview */}
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-secondary/10 rounded-2xl blur-3xl" />
            <div className="glass p-4 md:p-8 rounded-2xl">
              <h3 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-glow">Live Preview</h3>
              <div className="aspect-[16/20] bg-surface-light rounded-xl relative overflow-hidden">
                {activeFeature === 'multiProfile' && <ProfilePreview />}
                {activeFeature === 'animeChat' && <ChatPreview />}
                {activeFeature === 'animeChannel' && <ChannelPreview />}
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

// Preview Components with mobile optimization
const ProfilePreview = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    '/assets/images/main_profile.jpg',
    '/assets/images/anime_profile.jpg',
    '/assets/images/channel_profile.jpg'
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full h-full">
      <motion.img
        key={currentImageIndex}
        src={images[currentImageIndex]}
        alt="Profile Preview"
        className="w-full h-full object-cover"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      />
      <div className="absolute bottom-0 left-0 right-0 p-3 md:p-4 bg-gradient-to-t from-black/80 to-transparent">
        <h4 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">
          {currentImageIndex === 0 ? "Main Profile" : 
           currentImageIndex === 1 ? "Anime Profile" : "Channel Profile"}
        </h4>
        <p className="text-sm md:text-base text-white/80">
          {currentImageIndex === 0 ? "Your professional identity" : 
           currentImageIndex === 1 ? "Your anime persona" : "Your community presence"}
        </p>
      </div>
    </div>
  );
};

const ChatPreview = () => (
  <div className="w-full h-full">
    <img
      src="/assets/images/anime_chat.jpg"
      alt="Anime Chat Preview"
      className="w-full h-full object-cover"
    />
    <div className="absolute bottom-0 left-0 right-0 p-3 md:p-4 bg-gradient-to-t from-black/80 to-transparent">
      <h4 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">Anime Chat</h4>
      <p className="text-sm md:text-base text-white/80">Secure and anonymous communication</p>
    </div>
  </div>
);

const ChannelPreview = () => (
  <div className="w-full h-full">
    <img
      src="/assets/images/anime_channel_chat.jpg"
      alt="Anime Channel Preview"
      className="w-full h-full object-cover"
    />
    <div className="absolute bottom-0 left-0 right-0 p-3 md:p-4 bg-gradient-to-t from-black/80 to-transparent">
      <h4 className="text-lg md:text-xl font-bold text-white mb-1 md:mb-2">Anime Channel</h4>
      <p className="text-sm md:text-base text-white/80">Share and discover anime content</p>
    </div>
  </div>
);

export default ProductDemo;