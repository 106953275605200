import React, { useEffect, useState } from 'react';
import { ThemeProvider } from './styles/theme';
import ViewProvider from './components/ViewToggle';
import { app, auth, db } from './config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import AuthComponent from './components/AuthComponent';
import Hero from './components/Hero';
import ProductDemo from './components/ProductDemo';
import MarketOpportunity from './components/MarketOpportunity';
import SecurityPrivacy from './components/SecurityPrivacy';
import DevelopmentProgress from './components/DevelopmentProgress';
import GrowthStrategy from './components/GrowthStrategy';
import TechnologyStack from './components/TechnologyStack';
import CompetitiveAnalysis from './components/CompetitiveAnalysis';
import FounderVision from './components/FounderVision';
import Investment from './components/Investment';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles/global.css';

export const UserContext = React.createContext(null);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (!userDocSnapshot.exists()) {
          await setDoc(userDocRef, {
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
            createdAt: new Date(),
            lastLogin: new Date(),
          });
        } else {
          await setDoc(userDocRef, {
            lastLogin: new Date()
          }, { merge: true });
        }

        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  const publicComponents = (
    <>
      <Hero />
      <ProductDemo />
      <MarketOpportunity />
      <SecurityPrivacy />
      <DevelopmentProgress />
      <GrowthStrategy />
      <TechnologyStack />
      <CompetitiveAnalysis />
      <FounderVision />
      <Contact />
    </>
  );

  return (
    <ThemeProvider>
      <UserContext.Provider value={{ user, setUser }}>
        <ViewProvider>
          <div className="min-h-screen bg-background text-text">
            <main>
              {!user ? (
                <div className="container mx-auto px-4 py-8">
                  <AuthComponent />
                </div>
              ) : (
                <>
                  {publicComponents}
                  <Investment />
                </>
              )}
            </main>
            <Footer />
          </div>
        </ViewProvider>
      </UserContext.Provider>
    </ThemeProvider>
  );
};

export default App;