// File: src/styles/theme.js
import { createContext, useContext, useState } from 'react';

// Theme Context
export const ThemeContext = createContext();

// Theme Presets
export const themePresets = {
  // Cyberpunk Anime Theme
  cyber: {
    name: 'cyber',
    colors: {
      // Primary colors - Neon red tones for energy and impact
      primary: '#FF0066',      // Neon pink-red
      secondary: '#FF3D00',    // Bright orange-red
      accent: '#FF1493',       // Deep pink
      
      // Background colors - Dark with subtle color
      background: '#0A0A0F',   // Deep dark
      surface: '#12121F',      // Slightly lighter dark
      surfaceLight: '#1A1A2F', // Interactive surfaces
      surfaceHover: '#22223F', // Hover states
      
      // Text colors
      text: {
        DEFAULT: '#FFFFFF',
        secondary: '#B8B8E6',  // Slight purple tint
        muted: '#7676A3'       // Muted purple-gray
      },
      
      // Special effects colors
      glow: {
        primary: 'rgba(255, 0, 102, 0.5)',
        secondary: 'rgba(255, 61, 0, 0.5)',
        accent: 'rgba(255, 20, 147, 0.5)'
      },
      
      // Status colors
      success: '#00FF9F',      // Cyber green
      warning: '#FFB800',      // Bright warning
      error: '#FF0033',        // Error red
      info: '#00BFFF'          // Info blue
    },
    
    gradients: {
      // Background gradients
      primary: 'linear-gradient(135deg, #FF0066 0%, #FF3D00 100%)',
      secondary: 'linear-gradient(135deg, #FF3D00 0%, #FF0033 100%)',
      accent: 'linear-gradient(135deg, #FF1493 0%, #FF0066 100%)',
      background: 'linear-gradient(135deg, #0A0A0F 0%, #12121F 100%)',
      
      // Special effect gradients
      glow: 'linear-gradient(135deg, rgba(255, 0, 102, 0.1) 0%, rgba(255, 61, 0, 0.1) 100%)',
      cyber: 'linear-gradient(90deg, #FF0066 0%, #FF3D00 50%, #FF1493 100%)',
      grid: 'linear-gradient(90deg, rgba(255, 0, 102, 0.1) 1px, transparent 1px), linear-gradient(0deg, rgba(255, 0, 102, 0.1) 1px, transparent 1px)'
    },
    
    shadows: {
      // Neon glow effects
      neon: `
        0 0 5px rgba(255, 0, 102, 0.5),
        0 0 10px rgba(255, 0, 102, 0.3),
        0 0 15px rgba(255, 0, 102, 0.1)
      `,
      hover: `
        0 0 10px rgba(255, 0, 102, 0.7),
        0 0 20px rgba(255, 0, 102, 0.5),
        0 0 30px rgba(255, 0, 102, 0.3)
      `,
      text: `
        0 0 5px rgba(255, 0, 102, 0.5),
        0 0 10px rgba(255, 0, 102, 0.3)
      `,
      
      // Utility shadows
      elevation: '0 8px 32px rgba(0, 0, 0, 0.5)',
      soft: '0 4px 6px rgba(0, 0, 0, 0.3)',
      sharp: '0 2px 4px rgba(0, 0, 0, 0.5)'
    },
    
    animations: {
      // Keyframe animations
      float: {
        keyframes: {
          '0%, 100%': { transform: 'translateY(0)' },
          '50%': { transform: 'translateY(-10px)' }
        },
        duration: '3s',
        timing: 'ease-in-out',
        iteration: 'infinite'
      },
      pulse: {
        keyframes: {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.5 }
        },
        duration: '2s',
        timing: 'ease-in-out',
        iteration: 'infinite'
      },
      glitch: {
        keyframes: {
          '0%': { transform: 'translate(0)' },
          '20%': { transform: 'translate(-2px, 2px)' },
          '40%': { transform: 'translate(-2px, -2px)' },
          '60%': { transform: 'translate(2px, 2px)' },
          '80%': { transform: 'translate(2px, -2px)' },
          '100%': { transform: 'translate(0)' }
        },
        duration: '0.5s',
        timing: 'steps(1)',
        iteration: '1'
      },
      
      // Transition timings
      transition: {
        fast: '150ms cubic-bezier(0.4, 0, 0.2, 1)',
        normal: '300ms cubic-bezier(0.4, 0, 0.2, 1)',
        slow: '500ms cubic-bezier(0.4, 0, 0.2, 1)',
        bounce: '500ms cubic-bezier(0.68, -0.55, 0.265, 1.55)'
      }
    },
    
    // Component specific styles
    components: {
      // Card styles
      card: {
        base: `
          relative overflow-hidden
          bg-surface rounded-lg
          border border-primary/10
          backdrop-filter backdrop-blur-sm
        `,
        hover: `
          transform-gpu scale-[1.02]
          border-primary/30
          shadow-neon
        `,
        active: `
          transform-gpu scale-[0.98]
        `
      },
      
      // Button styles
      button: {
        base: `
          relative overflow-hidden
          px-6 py-3 rounded-lg
          font-semibold
          transition-all duration-300
        `,
        primary: `
          bg-gradient-to-r from-primary to-secondary
          text-white
          hover:shadow-neon
          active:scale-95
        `,
        secondary: `
          bg-surface
          text-text-secondary
          border border-primary/20
          hover:border-primary/40
          hover:text-white
          active:scale-95
        `
      },
      
      // Input styles
      input: `
        bg-surface
        border border-primary/20
        rounded-lg
        px-4 py-3
        text-text
        placeholder-text-muted
        focus:border-primary
        focus:ring-1
        focus:ring-primary
        outline-none
        transition-all
      `
    }
  }
};

// Theme Provider Component
export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState('cyber');

  const theme = {
    ...themePresets[currentTheme],
    setTheme: setCurrentTheme
  };

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
};

// Custom Hook for Using Theme
export const useTheme = () => {
  const theme = useContext(ThemeContext);
  if (!theme) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return theme;
};

// Utility Functions
export const createGlowEffect = (color, intensity = 1) => ({
  boxShadow: `
    0 0 ${5 * intensity}px ${color}80,
    0 0 ${10 * intensity}px ${color}40,
    0 0 ${15 * intensity}px ${color}20
  `
});

export const createGlassEffect = (opacity = 0.1, blur = 8) => ({
  backgroundColor: `rgba(18, 18, 31, ${opacity})`,
  backdropFilter: `blur(${blur}px)`,
  border: '1px solid rgba(255, 0, 102, 0.1)'
});

export default themePresets.cyber;

